import { RSet } from "../../collections";
import { makeImmutable } from "../../core";
import { Tristate } from "../../core/Tristate";
/*
 * Availability strategy based on Ordering Hours
 */
export class OrderingHoursAvailability {
    constructor(params) {
        this.scope = params.scope;
        this.orderingHours = params.orderingHours;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        this.enableDelayedDelivery = params.enableDelayedDelivery;
        this.availableDates = params.availableDates;
        makeImmutable(this);
    }
    get isAvailable() {
        if (this.orderingHours.canOrderAt(this.scope.now)) {
            return Tristate.True;
        }
        else {
            if (this
                .isSpecialBeheviourForAsapOnlyOnTheCurrentDayAnyTimeAfterOrderingHours) {
                return Tristate.False;
            }
            else if (this.canPreorder) {
                return Tristate.True;
            }
            else {
                return Tristate.False;
            }
        }
    }
    get findNextOrderingHourWithin7Days() {
        for (let day = 0; day < 7; day++) {
            const date = day == 0 ? this.scope.now : this.scope.now.add(day, "day").startOfDay();
            const nextEvent = this.orderingHours.nextEventAtDate(date);
            if (nextEvent.type === "OrderingEnds") {
                return null;
            }
            else {
                if (nextEvent.at !== null) {
                    return nextEvent.at;
                }
            }
        }
        return null;
    }
    get isBeforeOrderingHours() {
        var _a, _b;
        const nextEvent = this.orderingHours.nextEventAtDate(this.scope.now);
        return (nextEvent.type === "OrderingBegins" && ((_b = (_a = nextEvent.at) === null || _a === void 0 ? void 0 : _a.isToday) !== null && _b !== void 0 ? _b : false));
    }
    get isAfterOrderingHours() {
        const nextEvent = this.orderingHours.nextEventAtDate(this.scope.now);
        return nextEvent.type === "OrderingBegins" && nextEvent.at === null;
    }
    get isOutOfOrderingHours() {
        return !this.orderingHours.canOrderAt(this.scope.now);
    }
    get canPreorder() {
        if (this.isOutOfOrderingHours && !this.allowOrderingInClosedRestaurant) {
            return false;
        }
        return this.availableDates.availableDates.totalHoursCount > 0;
    }
    get isSpecialBeheviourForAsapOnlyOnTheCurrentDayAnyTimeBeforeOrderingHours() {
        if (this.isBeforeOrderingHours &&
            this.allowOrderingInClosedRestaurant &&
            !this.enableDelayedDelivery &&
            !this.availableDates.hasFutureDates) {
            return true;
        }
        return false;
    }
    get isSpecialBeheviourForAsapOnlyOnTheCurrentDayAnyTimeAfterOrderingHours() {
        if (this.isAfterOrderingHours &&
            this.allowOrderingInClosedRestaurant &&
            !this.enableDelayedDelivery &&
            this.availableDates.hasFutureDates) {
            return true;
        }
        return false;
    }
    get nextOrderAvailableAt() {
        const date = this.findNextOrderingHourWithin7Days;
        if (date === null) {
            return null;
        }
        else if (date.isToday) {
            return {
                type: "today",
                hour: date.fullHour,
            };
        }
        else if (date.isTomorrow) {
            return {
                type: "tomorrow",
                hour: date.fullHour,
            };
        }
        else {
            return {
                type: "later",
                date: date.format("DD.MM"),
            };
        }
    }
    get unavailabilityReasons() {
        return this.isAvailable.isDefinitelyTrue
            ? RSet.empty()
            : RSet.singleton("OrderingHours");
    }
    accept(availabilityVisitor) {
        availabilityVisitor.visitOrderingHours(this);
    }
}
