import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { Availability, OnTimeFulfillmentTime, } from "../../ordering";
import { RArray } from "../../collections";
export class PInFutureFulfillmentTime {
    constructor(params) {
        this.type = "InFuture";
        this.dateValue = null;
        this.hourValue = null;
        this.purchaseOrderFulfillmentTime = params.purchaseOrderFulfillmentTime;
        this.label = translate(t.orders.form.choose_date);
        makeAutoObservable(this);
    }
    get isAvailable() {
        return this.purchaseOrderFulfillmentTime.inFutureOptionAvailabile;
    }
    get dates() {
        return this.purchaseOrderFulfillmentTime.availableDates.availableDates;
    }
    get hours() {
        if (this.dateValue === null) {
            return RArray.empty();
        }
        return this.purchaseOrderFulfillmentTime.availableDates.availableDates.rawHoursForDate(this.dateValue);
    }
    get dateOptions() {
        // NOTICE: Options are currently limitted to 31 elements to not display very large list of options in the select field.
        // We are planning to change the way of selecting date and hour in the future so it will be removed.
        return this.dates.dayDates.take(31).raw.map((date) => ({
            label: `${getDayName(date)}, ${date.format("DD.MM")}`,
            value: date.fullDate,
        }));
    }
    get timeOptions() {
        return this.hours.raw.map((date) => ({
            label: date.fullHour,
            value: date.fullHour,
        }));
    }
    get dateDisabled() {
        return this.dates.isEmpty;
    }
    get timeDisabled() {
        return this.hours.isEmpty;
    }
    get domainDateValue() {
        return this.hours.findWhere((hour) => hour.fullHour === this.hourValue);
    }
    get domainValue() {
        return this.domainDateValue !== null
            ? new OnTimeFulfillmentTime({ date: this.domainDateValue })
            : null;
    }
    get availability() {
        return Availability.boolean({
            DateValueMissing: this.dateValue === null,
            HourValueMissing: this.hourValue === null,
            HourOptionsMissing: this.hours.isEmpty,
            UnavailableHourValue: this.hourValue !== null && this.domainDateValue === null,
        });
    }
}
function toLowerCase(str) {
    return str.toLowerCase();
}
function getDayName(date) {
    const translated = date.isToday
        ? translate(t.date.today)
        : date.isTomorrow
            ? translate(t.date.tomorrow)
            : translate(t.date.day.names[toLowerCase(date.weekday)]);
    // NOTICE: Capitalize first letter due to translations missmatch (monday, Friday, etc.)
    return translated.charAt(0).toUpperCase() + translated.slice(1);
}
