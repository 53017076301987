import { makeAutoObservable } from "mobx";
import { Availability, } from "../../ordering";
import { PAsapFulfillmentTime } from "./PAsapFulfillmentTime";
import { PTodayFulfillmentTime } from "./PTodayFulfillmentTime";
import { PInFutureFulfillmentTime } from "./PInFutureFulfillmentTime";
import { translate, t } from "../../I18n";
export class POnlineFulfillmentTime {
    constructor(params) {
        this.type = "Online";
        this.optionValue = null;
        const { purchaseOrderFulfillmentTime } = params;
        this.purchaseOrderFulfillmentTime = purchaseOrderFulfillmentTime;
        this.asapOption = new PAsapFulfillmentTime({
            purchaseOrderFulfillmentTime,
        });
        this.todayOption = new PTodayFulfillmentTime({
            purchaseOrderFulfillmentTime,
        });
        this.inFutureOption = new PInFutureFulfillmentTime({
            purchaseOrderFulfillmentTime,
        });
        makeAutoObservable(this);
    }
    get domain() {
        return this.purchaseOrderFulfillmentTime.fulfillmentTime;
    }
    get isEditable() {
        return this.options.length > 0;
    }
    get options() {
        const combinations = [
            [this.asapOption, this.inFutureOption],
            [this.asapOption, this.todayOption],
            [this.inFutureOption],
            [this.todayOption],
            [this.asapOption],
        ];
        const combination = combinations.find((combination) => {
            let isAvailable = true;
            for (const option of combination) {
                if (!option.isAvailable)
                    isAvailable = false;
            }
            return isAvailable;
        });
        return combination !== null && combination !== void 0 ? combination : [];
    }
    get activeOption() {
        for (const option of this.options) {
            if (option.type === this.optionValue) {
                return option;
            }
        }
        return null;
    }
    get formattedValue() {
        var _a;
        switch ((_a = this.domain) === null || _a === void 0 ? void 0 : _a.type) {
            case "ASAP":
                return translate(t.theme_defaults.hours.now);
            case "OnTime":
                return this.domain.date.isToday
                    ? this.domain.date.fullHour
                    : this.domain.date.format("DD.MM, HH:mm");
            default:
                return translate(t.restaurants.widgets.menu.plan_date);
        }
    }
    get hourValue() {
        var _a;
        switch ((_a = this.activeOption) === null || _a === void 0 ? void 0 : _a.type) {
            case "Today":
            case "InFuture":
                return this.activeOption.hourValue;
            default:
                return null;
        }
    }
    set hourValue(value) {
        if (this.activeOption === null) {
            return;
        }
        switch (this.activeOption.type) {
            case "Today":
            case "InFuture":
                this.activeOption.hourValue = value;
                break;
            default:
                throw new Error(`Setting hourValue for "${this.activeOption.type}" option is not allowed`);
        }
    }
    get dateValue() {
        var _a;
        switch ((_a = this.activeOption) === null || _a === void 0 ? void 0 : _a.type) {
            case "InFuture":
                return this.activeOption.dateValue;
            default:
                return null;
        }
    }
    set dateValue(value) {
        if (this.activeOption === null) {
            return;
        }
        switch (this.activeOption.type) {
            case "InFuture":
                this.activeOption.dateValue = value;
                break;
            default:
                throw new Error(`Setting dateValue for "${this.activeOption.type}" option is not allowed`);
        }
    }
    get availability() {
        return this.activeOption === null
            ? Availability.unavailable("OptionValueMissing")
            : this.activeOption.availability;
    }
    // TODO: remove this while we wont't allow to add a unavailable product to the Cart
    get selectedValueIsAvailable() {
        if (this.domain === null) {
            return true;
        }
        switch (this.domain.type) {
            case "ASAP":
                return this.purchaseOrderFulfillmentTime.asapOptionAvailable;
            case "OnTime": {
                const activeDate = this.domain.date;
                const availableDatesSerivce = this.purchaseOrderFulfillmentTime.availableDates;
                return availableDatesSerivce.availableDates
                    .hoursForDate(activeDate)
                    .findWhere((hour) => hour.fullHour === activeDate.fullHour)
                    ? true
                    : false;
            }
        }
    }
    clear() {
        this.purchaseOrderFulfillmentTime.fulfillmentTime = null;
    }
    save() {
        var _a, _b;
        const domainValue = (_b = (_a = this.activeOption) === null || _a === void 0 ? void 0 : _a.domainValue) !== null && _b !== void 0 ? _b : null;
        const isValid = domainValue !== null && this.availability.isAvailable.isDefinitelyTrue;
        if (isValid) {
            this.purchaseOrderFulfillmentTime.fulfillmentTime = domainValue;
        }
        return isValid;
    }
}
